import React from 'react'

const InputLabel = ({label}) => {
  return (
    <label
    for="email"
    class="block mb-2 text-sm font-medium text-gray-600 "
  >
    {label}
  </label>
  )
}

export default InputLabel