import React from 'react'
import PrimaryButton from './PrimaryButton';

const PricingCard = ({title,price,description,list}) => {
  const CheckIcon = ({ fill = 'currentColor' }) => (
    <svg
      className="w-6 h-6 text-gray-800"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill={fill}
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 11.917L9.724 16.5 19 7.5"
      />
    </svg>
  );
  const openWhatsApp = (plan) => {
    const message = `Hello, I'm interested in the ${plan} plan. Please provide more details.`;
    const whatsappNumber = "+923492363446";
    const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };
  return (
    <div className="  shadow-lg rounded-lg p-8 text-left md:col-span-1">
              <h3 className="text-xl font-semibold mb-4">{title}</h3>
              <ul className="space-y-2 mb-6 text-gray-700 flex flex-col items-start text-left">
                {list.map((item, index) => (
                  <li key={index} className="flex items-center">
                    <CheckIcon />
                    {item}
                  </li>
                ))}
              </ul>
              <span className="text-3xl font-bold text-gray-600">{price}</span>
              <p className="mt-4 text-gray-600">{description}</p>
              <PrimaryButton
                onClick={() => openWhatsApp(title)}
                className="mt-4 w-full">Get Started
              </PrimaryButton>
            </div>
  )
}

export default PricingCard