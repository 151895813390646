import React from "react";


const PortfollioCard = ({ url, title, description, className }) => {
  return (
    <div
      className={`p-4 flex flex-col sm:flex-row items-center sm:items-start ${className} max-w-xs sm:max-w-md lg:max-w-full border border-gray-300 rounded-xl shadow-lg`}
    >
      <img
        src={url}
        alt={title}
        className="object-cover w-full h-48 sm:w-1/2 sm:h-auto rounded-lg sm:rounded-none sm:mr-4"
      />
      <div className="flex flex-col items-start justify-start text-center sm:text-left mt-4 sm:mt-0 sm:ml-6 w-full">
        <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-800">{title}</h2>
        <p className="text-gray-600 mt-2 text-sm sm:text-base md:text-lg">{description}</p>
      </div>
    </div>
  );
};

export default PortfollioCard;
