import React, { useState, useEffect, useRef } from "react";
// import PrimaryButton from "./PrimaryButton";
import { FaArrowUp } from "react-icons/fa6";
const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio("https://vertexinvo.com//close.wav");
  }, []);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const playClickTone = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <FaArrowUp
          size={30}
          onClick={() => {
            playClickTone();
            scrollToTop();
          }}
          className="fixed bottom-8 right-8 transition duration-300 cursor-pointer"
        />
      )}
    </div>
  );
};

export default ScrollToTop;
