import React, { useState, useRef, useEffect } from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-scroll";

const Navbar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [isOpen, setIsOpen] = useState(false);
  const links = ["home", "about", "services", "portfolio", "pricing", "contact"];
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio("https://vertexinvo.com//click.wav");
  }, []);

  const handleSetActive = (link) => {
    setActiveLink(link);
  };

  const playClickTone = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  return (
    <nav className="fixed top-0 z-50 w-full py-4 bg-gray-100">
      <div className="max-w-screen-lg flex flex-wrap items-center justify-between mx-auto">
        <a
          href="#home"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img
            src="https://vertexinvo.com//vertexinvo_logo.png"
            className="h-12 w-12"
            alt="vertexinvo_logo"
          />
        </a>
        <button
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          onClick={() => setIsOpen(!isOpen)}
          aria-controls="navbar-solid-bg"
          aria-expanded={isOpen}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`md:block ${isOpen ? "block" : "hidden"} w-full md:w-auto`}
          id="navbar-solid-bg"
        >
          <ul className="flex flex-col text-sm font-medium mt-4 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent">
            {links.map((item) => (
              <li key={item}>
                <Link
                  to={item}
                  spy={true}
                  smooth={true}
                  duration={500}
                  onSetActive={() => handleSetActive(item)}
                  onClick={() => { playClickTone(); setIsOpen(!isOpen);}}
                  className={`block py-2 px-3 md:p-0 ${
                    activeLink === item
                      ? "text-[#C3EF62]"
                      : "text-gray-600 "
                  }  hover:text-[#C3EF62] cursor-pointer`}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
