import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import PortfollioCard from "./Components/PortfollioCard";
import ScrollToTop from "./Components/ScrollToTop";
import Particles from "react-tsparticles";
import { useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import { loadSlim } from "tsparticles-slim";
import PrimaryButton from "./Components/PrimaryButton";
import InputLabel from "./Components/InputLabel";
import PricingCard from "./Components/PricingCard";
import { Formik, Form, Field, ErrorMessage, validateYupSchema } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import AWS from 'aws-sdk';
import EmailTemplate from "./Components/EmailTemplate";
import ReactDOMServer from 'react-dom/server';


function App() {
  const [onQuerySent, setOnQuerySent] = useState(false);


  
AWS.config.update({
  region: 'us-east-1',
  accessKeyId: 'AKIAXNGUU53Y5GUPXF7H',
  secretAccessKey: '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',
});


const sendEmail = async (name, email, city, country, company, subject, message) => {
  setOnQuerySent(true);
  const htmlString = ReactDOMServer.renderToStaticMarkup(
    <EmailTemplate
      name={name}
      email={email}
      city={city}
      country={country}
      company={company}
      subject={subject}
      message={message}
    />
  );
  const ses = new AWS.SES({ region: 'us-east-1' });
  const params = {
    Source: 'vertexinvo@gmail.com',
    Destination: { ToAddresses: ['vertexinvo@gmail.com'] },
    Message: {
      Subject: { Data: 'Contact Request' },
      Body: {
        Html: { Data: htmlString },
      },
    },
  };

  try {
    await ses.sendEmail(params).promise();
    setOnQuerySent(false);
    toast.success('Query sent successfully');
  } catch (err) {
    setOnQuerySent(false);
    toast.error('Error sending query! Please try again later.');
  }
};

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').required('Email is required'),
    name: Yup.string().required('Name is required'),
    city: Yup.string().required('City is required'),
    country: Yup.string().required('Country is required'),
    company: Yup.string().required('Company is required'),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
  });
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const particlesOptions = {
    fpsLimit: 120,
    interactivity: {
      events: {
        onClick: { enable: true, mode: "push" },
        resize: true,
      },
      modes: {
        push: { quantity: 1 },
        repulse: { distance: 200, duration: 0.4 },
      },
    },
    particles: {
      color: { value: "#C3EF62" },
      links: {
        color: "#DCDCDC",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      move: {
        direction: "none",
        enable: true,
        outModes: { default: "bounce" },
        speed: 1,
      },
      number: { density: { enable: true, area: 800 }, value: 50 },
      opacity: { value: 0.75 },
      shape: { type: "" },
      size: { value: { min: 1, max: 3 } },
    },
    detectRetina: true,
  };

  return (
    <div className="App bg-gray-100 ">
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={particlesOptions}
      />
      <ScrollToTop />
      <ToastContainer />
      <Navbar />
      <section className="h-screen" id="home">
        <div className="py-20 px-6 mx-auto max-w-screen-lg flex flex-col justify-center h-full text-center">
          <img
            src="https://vertexinvo.com//vertexinvo_hero_section.png"
            className="mx-auto h-auto max-w-full relative"
            alt="logo"
          />
        
        </div>
      </section>

      <section className="py-10 mx-auto max-w-screen-lg text-center relative">
        <div className="border border-gray-800  py-8 px-4 mx-auto lg:py-16 lg:px-6 shadow-md rounded-xl">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-black mb-6">
            Our Achievements
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 lg:gap-16">
            <div className="flex flex-col items-center transition-transform transform hover:scale-105">
              <span className="text-5xl sm:text-4xl font-bold text-black">
                300+
              </span>
              <p className="mt-2 text-base font-medium text-black">
                Stunning Websites Built
              </p>
            </div>
            <div className="flex flex-col items-center transition-transform transform hover:scale-105">
              <span className="text-5xl sm:text-4xl font-bold text-black">
                200+
              </span>
              <p className="mt-2 text-base font-medium text-black">
                Innovative Mobile Apps Delivered
              </p>
            </div>
            <div className="flex flex-col items-center transition-transform transform hover:scale-105">
              <span className="text-5xl sm:text-4xl font-bold text-black">
                1,000+
              </span>
              <p className="mt-2 text-base font-medium text-black">
                Brands Amplified
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="  min-h-screen flex items-center justify-center"
        id="about"
      >
        <div className="px-4 mx-auto max-w-screen-lg text-center">
          <h1 className="relative mb-8 text-3xl md:text-4xl lg:text-5xl text-gray-600">
            Who We Are?
          </h1>
          <div className="flex flex-col items-center space-y-8 sm:flex-row sm:space-y-0 sm:space-x-8">
            <div className="flex-shrink-0 w-full sm:w-1/2 lg:w-2/5">
              <img
                src="https://vertexinvo.com//workspace.jpeg"
                className="object-cover w-full h-full rounded-lg grayscale hover:grayscale-0 sm:h-96"
                alt="Business Team"
              />
            </div>
            <div className="w-1/2">
              <p className="font-light text-gray-600 text-start">
                At VertexInvo, we are passionate about transforming your digital
                vision into reality. With a dedicated team of experts in web
                development, mobile app creation, and digital marketing, we
                strive to deliver innovative solutions that elevate your
                business and enhance your online presence. Founded on the
                principles of quality, creativity, and customer satisfaction,
                VertexInvo is committed to providing cutting-edge services
                tailored to meet the unique needs of each client. Whether you’re
                a startup looking to establish your brand or an established
                business seeking to enhance your digital footprint, we have the
                expertise to help you succeed.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="relative min-h-screen flex items-center"
        id="services"
      >
        <div className="mx-auto max-w-screen-lg px-4 flex flex-col justify-center">
          <h2 className="text-center text-3xl md:text-4xl font-extrabold">
            Services & Solutions
          </h2>
          <p className="text-center text-lg text-gray-400 mb-5">
            Tailored solutions to elevate your brand and drive growth.
          </p>

          <div className="relative grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
            <div className="border border-gray-800 rounded-xl p-6 md:col-span-3 lg:col-span-3 lg:row-span-2">
              <h3 className="text-xl font-semibold">Web Development</h3>
              <p className="text-gray-400 mt-2">
                Our website development company works with clients based all
                over Pakistan and worldwide. A website is the central tool for
                the visibility of a business on the Internet. An effective
                website will not only strengthen the attraction but also the
                acquisition and retention of new customers.
              </p>
            </div>

            <div className="border border-gray-800 rounded-xl p-6 md:col-span-3 lg:col-span-3 lg:row-span-2">
              <h3 className="text-xl font-semibold">Mobile App Development</h3>
              <p className="text-gray-400 mt-2">
                To improve your digital strategy, a mobile application allowsk
                you to open new distribution channels for your business. A
                dedicated application improves brand visibility, builds customer
                loyalty, promotes products, and enhances business processes.
              </p>
            </div>

            <div className="border border-gray-800 rounded-xl p-6 lg:col-span-2">
              <h3 className="text-xl font-semibold">Artificial Intelligence</h3>
              <p className="text-gray-400 mt-2">
                Transform your business with AI services that improve
                decision-making, reduce operational costs, and elevate customer
                experience.
              </p>
            </div>

            <div className="border border-gray-800 rounded-xl p-6 lg:col-span-2">
              <h3 className="text-xl font-semibold">
                Digital Marketing Solutions
              </h3>
              <p className="text-gray-400 mt-2">
                We offer digital marketing for your brand, combining marketing
                with design and technology to provide a complete package aimed
                at boosting customer satisfaction.
              </p>
            </div>

            <div className="border border-gray-800 rounded-xl p-6 lg:col-span-2">
              <h3 className="text-xl font-semibold">Graphic Designing</h3>
              <p className="text-gray-400 mt-2">
                Our design services create visual identities that stand out,
                attract attention, and exceed expectations, helping brands
                define their future.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="min-h-screen"
        id="portfolio"
      >
        <div className="mx-auto max-w-screen-lg px-4 flex flex-col justify-start">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold tracking-tight leading-tight text-gray-800 font-sans py-4 text-center mb-4">
            Our Portfolio
          </h1>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            autoplay
            pagination={{ clickable: true }}
            loop
            className="w-full"
          >
            <SwiperSlide>
              <PortfollioCard
                url="https://vertexinvo.com//web1.png"
                title="AI Legal Knowledge Base"
                description="A knowledge base platform powered by AI, designed for legal professionals to get quick and accurate information."
                className=" md:flex-row px-4 md:px-8"
              />
            </SwiperSlide>
            <SwiperSlide>
              <PortfollioCard
                url="https://vertexinvo.com//web2.png"
                title="Payment System"
                description="A secure and scalable payment solution for businesses to process transactions with ease."
                className=" md:flex-row px-4 md:px-8"
              />
            </SwiperSlide>
            <SwiperSlide>
              <PortfollioCard
                url="https://vertexinvo.com//web3.png"
                title="Logistics Solution"
                description="Logistics and express delivery solution tailored for efficient transportation of goods."
                className=" md:flex-row px-4 md:px-8"
              />
            </SwiperSlide>

            <SwiperSlide>
              <PortfollioCard
                url="https://vertexinvo.com//app1.png"
                title="Tutorial App"
                description="A mobile application that helps students and educators learn and share knowledge."
                className=" md:flex-row px-4 md:px-8"
              />
            </SwiperSlide>

            <SwiperSlide>
              <PortfollioCard
                url="https://vertexinvo.com//app2.png"
                title="Ecommerce Marketplace"
                description="An online platform for selling and buying products, providing a convenient and secure marketplace."
                className=" md:flex-row px-4 md:px-8"
              />
            </SwiperSlide>

            <SwiperSlide>
              <PortfollioCard
                url="https://vertexinvo.com//app3.png"
                title="Ecommerce Marketplace"
                description="An online platform for selling and buying products, providing a convenient and secure marketplace."
                className=" md:flex-row px-4 md:px-8"
              />
            </SwiperSlide>

            <SwiperSlide>
              <PortfollioCard
                url="https://vertexinvo.com//app4.png"
                title="Translation App"
                description="A mobile application that helps users translate text from one language to another."
                className=" md:flex-row px-4 md:px-8"
              />
            </SwiperSlide>

            <SwiperSlide>
              <PortfollioCard
                url="https://vertexinvo.com//app5.png"
                title="Ecommerce Marketplace"
                description="An online platform for selling and buying products, providing a convenient and secure marketplace."
                className=" md:flex-row px-4 md:px-8"
              />
            </SwiperSlide>

            <SwiperSlide>
              <PortfollioCard
                url="https://vertexinvo.com//app6.png"
                title="Krazy Klean"
                description="Cleaning and sanitizing services that provides a convenient and secure marketplace."
                className=" md:flex-row px-4 md:px-8"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section className=" min-h-screen flex items-center" id="pricing">
        <div className="mx-auto max-w-screen-lg px-4 flex flex-col justify-center">
          <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-600">
            Our Plans
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-screen-lg mx-auto px-4">
         
            <PricingCard
              title="Professional"
              description={
                "All Basic Services. Unlimited Requests. Unlimited Revisions. Cancel Anytime. A-Sync Collaboration and Support 24/7"
              }
              list={[
                "All Basic Services",
                "Unlimited Requests",
                "Unlimited Revisions",
                "Cancel Anytime",
                "A-Sync Collaboration",
              ]}
              />

<PricingCard
              title="Business"
              description={
                "All Professional Services. Priority Support. Weekly Check-ins. Onboarding Session. Performance Analytics"
              }
              list={[
                "All Professional Services",
                "Priority Support",
                "Weekly Check-ins",
                "Onboarding Session",
                "Performance Analytics",
              ]}
              />

<PricingCard
              title="Enterprise"
              description={
                "All Business Services. Custom Solutions. Dedicated Account Manager. Tailored Analytics. Comprehensive Support"
              }
              list={[
                "All Business Services",
                  "Custom Solutions",
                  "Dedicated Account Manager",
                  "Tailored Analytics",
                  "Comprehensive Support",
              ]}
              />

          </div>
        </div>
      </section>

      <section className="min-h-screen flex items-center" id="contact">
        <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-lg">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-600 ">
            Get in touch
          </h2>
          <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 sm:text-xl">
            How can we help you? Want to know more about our services? Need
            details about our Business plan? Let us know.
          </p>

            <Formik
      initialValues={{
        email: '',
        name: '',
        city: '',
        country: '',
        company: '',
        subject: '',
        message: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        sendEmail(
           values.name,
           values.email,
          values.city,
           values.country,
           values.company,
           values.subject,
          values.message,
        );
        values.email = '';
        values.name = '';
        values.city = '';
        values.country = '';
        values.company = '';
        values.subject = '';
        values.message = '';
      }}
    >
      {({ handleChange }) => (
        <Form className="relative space-y-8">
          <div className="grid md:grid-cols-2 md:gap-6">
            <div>
              <InputLabel label="Your email*" />
              <Field
                name="email"
                type="email"
                placeholder="Email*"
                className="block p-3 w-full text-sm text-gray-600 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-lime-400 "
                onChange={handleChange}
              />
              <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
            </div>

            <div>
              <InputLabel label="Your name*" />
              <Field
                name="name"
                type="text"
                placeholder="Name*"
                className="block p-3 w-full text-sm text-gray-600 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-lime-400 "
               onChange={handleChange}
              />
              <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
            </div>

            <div>
              <InputLabel label="Enter city*" />
              <Field
                name="city"
                type="text"
                placeholder="City*"
                className="block p-3 w-full text-sm text-gray-600 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-lime-400 "
                onChange={handleChange}
              />
              <ErrorMessage name="city" component="div" className="text-red-500 text-sm" />
            </div>

            <div>
              <InputLabel label="Enter country*" />
              <Field
                name="country"
                type="text"
                placeholder="Country*"
                className="block p-3 w-full text-sm text-gray-600 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-lime-400 "
                onChange={handleChange}
              />
              <ErrorMessage name="country" component="div" className="text-red-500 text-sm" />
            </div>

            <div>
              <InputLabel label="Enter company*" />
              <Field
                name="company"
                type="text"
                placeholder="Company*"
                className="block p-3 w-full text-sm text-gray-600 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-lime-400 "
                onChange={handleChange}
              />
              <ErrorMessage name="company" component="div" className="text-red-500 text-sm" />
            </div>

            <div>
              <InputLabel label="Enter subject*" />
              <Field
                name="subject"
                type="text"
                placeholder="Subject*"
                className="block p-3 w-full text-sm text-gray-600 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-lime-400 "
                onChange={handleChange}
              />
              <ErrorMessage name="subject" component="div" className="text-red-500 text-sm" />
            </div>
          </div>

          <div className="sm:col-span-2">
            <InputLabel label="Your message*" />
            <Field
              as="textarea"
              name="message"
              rows="6"
              placeholder="Type your message..."
              className="block p-2.5 w-full text-sm text-gray-600 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-lime-400"
              onChange={handleChange}
            />
            <ErrorMessage name="message" component="div" className="text-red-500 text-sm" />
          </div>

          <PrimaryButton type="submit" disabled={onQuerySent}>
            {onQuerySent ? 'Sending...' : 'Send Message'}
          </PrimaryButton>
        </Form>
      )}
    </Formik>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;
