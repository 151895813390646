import React from 'react'
import PropTypes from 'prop-types';
const EmailTemplate = ({name, email, city, country, company, subject, message}) => {
  return (
    <div class="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
    <h2 class="text-2xl font-bold text-gray-800 mb-4">New Message Received</h2>
    <div class="space-y-2">
      <p><span class="font-semibold text-gray-700">Name:</span> {name}</p>
      <p><span class="font-semibold text-gray-700">Email:</span> {email}</p>
      <p><span class="font-semibold text-gray-700">City:</span> {city}</p>
      <p><span class="font-semibold text-gray-700">Country:</span> {country}</p>
      <p><span class="font-semibold text-gray-700">Company:</span> {company}</p>
      <p><span class="font-semibold text-gray-700">Subject:</span> {subject}</p>
      <p><span class="font-semibold text-gray-700">Message:</span></p>
      <p class="text-gray-600 whitespace-pre-line">{message}</p>
    </div>
  </div>
  )
}

export default EmailTemplate