import React from 'react';

const PrimaryButton = ({ children, className,onClick, ...props }) => {
    return (
        <button {...props} className={`bg-black text-white font-medium py-2 px-4 rounded-md hover:bg-gray-800 transition duration-300 ${className}`} onClick={onClick}>
            {children}
        </button>
    );
};

export default PrimaryButton;
